import {
  ref, watch, onUnmounted, getCurrentInstance,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import bannerStoreModule from '../bannerStoreModule';

export default function useBannerList() {
  const APARTMENT_STORE_MODULE_NAME = "banner-admin";

  // Register module
  if (!store.hasModule(APARTMENT_STORE_MODULE_NAME)) {
    store.registerModule(APARTMENT_STORE_MODULE_NAME, bannerStoreModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(APARTMENT_STORE_MODULE_NAME)) {
      store.unregisterModule(APARTMENT_STORE_MODULE_NAME);
    }
  });
  // Use toast
  const toastification = toast();

  const blankItem = {
    imageFile: null,
    image: '',
    name: '',
    index: 1,
    url: '',
    content: '',
    active: true,
  };

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  // Table Handlers
  const columns = [
    {
      label: 'STT',
      field: 'index',
      width: '60px',
      tdClass: 'text-center',
      thClass: 'text-center',
    },
    {
      label: 'Thao tác',
      field: 'action',
      width: '120px',
      sortable: false,
    },
    {
      label: 'Tên',
      field: 'name',
    },
    {
      label: 'Hình ảnh',
      field: 'image',
    },
    {
      label: 'Hiển thị?',
      field: 'active',
    },

  ];
  const analytics = ref();
  const rows = ref([]);
  // filter
  const active = ref(null);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {},
    sort: {},
    page: 1,
    perPage: 20,
  });

  //   API Call

  const fetchBanners = () => {
    store
      .dispatch('banner-admin/fetchBanners', serverParams.value)
      .then(response => {
        const { items, total } = response.data;
        totalRecords.value = total;
        rows.value = items;
      })
      .catch(error => {
        toastification.showToastError(error);
        isLoading.value = false;
      });
  };
  const fetchData = () => {
    isLoading.value = true;
    fetchBanners();
  };
  const importData = data => {
    const formData = new FormData();
    formData.append('file', data.file);
    store
      .dispatch('banner-admin/importBanners', formData)
      .then(() => {
        fetchData();
        toastification.showToastCreateSuccess();
        const vm = getCurrentInstance().proxy;
        vm.$router
          .push({ name: 'jobs' });
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const exportData = () => {
    store
      .dispatch('banner-admin/exportBanners', serverParams.value)
      .then(response => {
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const deleteBanners = banners => {
    store
      .dispatch('banner-admin/deleteBanners', {
        ids: banners.map(_obj => _obj.id),
      })
      .then(() => {
        fetchData();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const onEditItem = val => {
    item.value = val;
  };
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchData();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  //   Watch
  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });
  watch(active, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.active = val.value;
    } else {
      delete filter.active;
    }
    updateParams({ filter });
  });

  return {
    analytics,
    item,
    columns,
    rows,
    active,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,

    fetchData,
    fetchBanners,
    deleteBanners,
    onEditItem,
    resetItem,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    importData,
    exportData,
  };
}
