import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchBanners(ctx, params) {
      return useJwt.getAdminBanners(params).then(response => response);
    },
    getBannerDetail(ctx, id) {
      return useJwt.getAdminBannerDetail(id).then(response => response);
    },
    createBanner(ctx, data) {
      return useJwt.createBanner(data).then(response => response);
    },
    updateBanner(ctx, data) {
      return useJwt.updateBanner(data).then(response => response);
    },
    deleteBanners(ctx, data) {
      return useJwt.deleteBanner(data).then(response => response);
    },
  },
};
