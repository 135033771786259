<template>
  <div class="banner-list-container">

    <b-card no-body>
      <b-card-header>
        <b-card-title>Danh sách banner</b-card-title>
        <crud-buttons
          name="banner"
          modal="modal-banner"
          :selected-rows="selectedRows"
          :show-import="$can('create', 'banner')"
          :show-export="$can('read', 'banner')"
          :hide-delete="!$can('delete', 'banner')"
          @on-delete="onDelete"
          @on-add="resetItem"
          @on-import-file="importData"
          @on-export="exportData"
        />
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col md="6">
            <select-active-status v-model="active" />
          </b-col>
          <b-col md="6">
            <b-form-input
              v-model="searchTerm"
              placeholder="Tìm kiếm banner..."
              debounce="1000"
            />
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <vue-good-table
              style-class="vgt-table striped bordered"
              mode="remote"
              :total-rows="totalRecords"
              :is-loading.sync="isLoading"
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: false,
              }"
              :pagination-options="{
                enabled: true,
                perPage: serverParams.perPage,
              }"
              @on-selected-rows-change="selectionChanged"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
            >
              <template slot="loadingContent">
                <b-spinner
                  label="Loading"
                  type="grow"
                />
              </template>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Code -->
                <span v-if="props.column.field === 'image'">
                  <avatar-view
                    :src="props.row.image"
                    :width="300"
                    :height="200"
                  />
                </span>

                <span v-else-if="props.column.field === 'active'">
                  <b-badge
                    :variant="props.row.active ? 'light-primary' : 'light-danger'"
                    class="font-small-1"
                  >
                    <span>{{ props.row.active ? "Có" : "Không" }}</span>
                  </b-badge>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <table-actions
                    name="banner"
                    modal="modal-banner"
                    @on-edit="onEditItem(props.row)"
                    @on-delete="onDelete(props.row)"
                  />
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Hiển thị tối đa </span>
                    <b-form-select
                      v-model="serverParams.perPage"
                      :options="['10', '20', '50', '100', '500']"
                      class="mx-1"
                      @input="
                        (value) =>
                          props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      trên tổng số {{ props.total }} kết quả
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="serverParams.perPage"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
              <div slot="emptystate">
                <div class="text-center text-muted">
                  Không có bản ghi nào!
                </div>
              </div>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <banner-modal
      :item="item"
      @refetch-data="fetchData"
    />

  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BPagination,
  BFormSelect,
  BSpinner,
  BBadge,
} from "bootstrap-vue";

import SelectActiveStatus from "@/views/components/SelectActiveStatus.vue";
import { VueGoodTable } from "vue-good-table";
import TableActions from "@/views/components/TableActions.vue";
import CrudButtons from "@/views/components/CrudButtons.vue";
// eslint-disable-next-line import/no-cycle
import AvatarView from '@/views/components/AvatarView.vue';
import useBannerList from "./useBannerList";
import BannerModal from "../modal/BannerModal.vue";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BPagination,
    BFormSelect,
    BSpinner,
    BBadge,
    TableActions,
    CrudButtons,

    SelectActiveStatus,
    VueGoodTable,
    BannerModal,
    AvatarView,
  },

  setup() {
    const {
      analytics,
      item,
      columns,
      rows,
      active,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,

      fetchData,
      fetchBanners,
      deleteBanners,
      onEditItem,
      resetItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      importData,
      exportData,
    } = useBannerList();

    return {
      analytics,
      item,
      columns,
      rows,
      active,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,

      fetchData,
      fetchBanners,
      deleteBanners,
      onEditItem,
      resetItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      importData,
      exportData,
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    onDelete(banner) {
      const deleteObjects = banner && banner.id > 0 ? [banner] : this.selectedRows;
      this.deleteBanners(deleteObjects);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

</style>
