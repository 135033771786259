<template>
  <div>
    <b-avatar
      v-if="src === null || src === undefined || src.length === 0"
      :style="{width: `${width}px !important`, height: `${height}px !important`}"
      fluid
      square
      rounded="lg"
      size="100px"
      variant="light-primary"
    />
    <b-img
      v-if="src !== null && src !== undefined && src.length > 0"
      :style="{width: `${width}px !important`, height: `${height}px !important`} "
      v-bind="mainProps"
      fluid
      :src="src"
      thumbnail
      square
      @click="$emit('on-clicked')"
    />
  </div>

</template>

<script>
import {
  BAvatar, BImg,
} from 'bootstrap-vue';

export default {
  components: {
    BAvatar,
    BImg,
  },
  props: {
    align: {
      type: String,
      default: 'left',
    },
    label: {
      type: String,
      default: null,
    },
    src: {
      type: String,
      default: null,
    },
    width: {
      type: Number,
      default: 100,
    },
    height: {
      type: Number,
      default: 100,
    },
  },
  data: () => ({
    mainProps: {
      width: 100,
      height: 100,
      class: 'm1',
    },
  }),
};
</script>
