<template>
  <b-modal
    id="modal-banner"
    ref="refModal"
    title="Banner"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    size="lg"
    :ok-title="isSubmitting ? 'Đang lưu' : 'Lưu'"
    :ok-disabled="isSubmitting"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row class="mt-2">
          <b-col cols="12">

            <image-view
              v-model="itemLocal.imageFile"
              align="center"
              :src="itemLocal.image"
              :width="300"
              :height="200"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  Tên banner <span class="text-danger">(*)</span>
                </template>
                <b-input-group>
                  <b-form-input
                    id="name"
                    v-model="itemLocal.name"
                    placeholder="Hướng dẫn sử dụng tính năng ký hợp đồng"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                </b-input-group>
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <b-form-group>
              <template v-slot:label>
                Thứ tự hiển thị <span class="text-danger">(*)</span>
              </template>
              <b-form-spinbutton
                v-model="itemLocal.index"
                min="1"
                max="100"
              />
            </b-form-group>

          </b-col>

          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="url"
              rules=""
            >
              <b-form-group>
                <template v-slot:label>
                  Đường dẫn
                </template>
                <b-input-group>
                  <b-form-input
                    id="address"
                    v-model="itemLocal.url"
                    placeholder="https://docs.resident.vn"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                </b-input-group>
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Active/Inactive -->

          <!-- submit -->
        </b-row>
        <b-row>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="Nội dung"
              rules=""
            >
              <b-form-group
                label="Nội dung thông báo"
              >
                <template
                  v-slot:label
                >
                  Nội dung
                </template>
                <editor
                  v-model="itemLocal.content"
                  :default-value="itemLocal.content"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="mt-2"
          >
            <b-form-group>
              <b-form-checkbox
                v-model="itemLocal.active"
                name="check-button"
                switch
                inline
              >
                Hiển thị
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>

  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BForm,
  BInputGroup,
  BFormCheckbox,
  BRow,
  BCol,
  BFormSpinbutton,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import Editor from '@/views/components/Editor.vue';
import ImageView from '@/views/components/ImageView.vue';
import useBannerModal from './useBannerModal';

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BForm,
    BInputGroup,
    BCol,
    BRow,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,

    BFormSpinbutton,
    Editor,
    ImageView,

  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      resetItemLocal,
      resetModal,
      isSubmitting,
      onSubmit,
    } = useBannerModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
